import { Autocomplete as MUIAutocomplete } from '@mui/material';
import { styled } from '@frontend/theme';
import { Box as MUIBox } from '@mui/material';

export const SearchAutocompleteSt = styled(MUIAutocomplete)(({ theme }) => ({
  '& fieldset': {
    border: 'none',
  },
  '& .MuiTextField-root .MuiInputBase-root': {
    height: 'auto',
  },
  '& .MuiOutlinedInput-root input.MuiInputBase-input.MuiOutlinedInput-input': {
    padding: '0',
  },
  padding: '0px',
  border: `1px solid ${theme.palette.neutral[200]}`,
  '&.Mui-focused': {
    border: `1px solid ${theme.palette.primary[500]}`,
  },
}));

export const SearchBarWrapper = styled(MUIBox)(({ theme }) => ({
  border: 'none',
  alignItems: 'center',
  padding: '0',
  height: '48px',
  background: theme.palette.common.white,
  '& .MuiAutocomplete-option': {
    fontSize: '16px',
    '& b': {
      fontWeight: 500,
    },
    '&.Mui-focused': {
      backgroundColor: `${theme.palette.neutral[50]}`,
    },
  },
}));
