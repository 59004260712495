import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { Drawer, DrawerHeader, DrawerBody } from './DrawerWithHeader.css';
import { DrawerWithHeaderProps } from './DrawerWithHeader.types';
import { RiCloseLine } from '@remixicon/react';
import IconButton from '../IconButton/IconButton';
import { useIntl } from 'react-intl';

function DrawerWithHeader({
  children,
  headerLine,
  id,
  onClose,
  open,
}: DrawerWithHeaderProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('tablet'));
  const intl = useIntl();

  return (
    <Drawer id={id} anchor="right" onClose={onClose} open={open}>
      <DrawerHeader>
        {headerLine && (
          <Typography component="h5" variant={isMobile ? 'h4' : 'h5'}>
            {headerLine}
          </Typography>
        )}
        <IconButton
          id={`${id ?? 'drawer'}.icon-button.close`}
          onClick={onClose}
          aria-label={intl.formatMessage({
            id: 'customer-platform.shared.close',
          })}
        >
          <RiCloseLine size={24} color={theme.palette.neutral[500]} />
        </IconButton>
      </DrawerHeader>
      <DrawerBody>{children}</DrawerBody>
    </Drawer>
  );
}

export default DrawerWithHeader;
