import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useContext } from 'react';
import { ApiContext } from '../ApiContext/ApiContext';
import axiosClient from '../axiosClient';

import { IProfileFormSchema, IUserProfileErrors } from './updateProfile.types';
import { getQueryKey as getUserProfileQueryKey } from '../getUserProfile/getUserProfile.const';

const submitUpdateProfile = async (
  profile: IProfileFormSchema,
  apiUrl: string,
) => {
  return axiosClient.put(`${apiUrl}/accounts/user/profile-data/`, profile);
};

const useUpdateProfile = () => {
  const { apiUrl } = useContext(ApiContext);
  const queryClient = useQueryClient();

  return useMutation<
    AxiosResponse,
    AxiosError<IUserProfileErrors>,
    IProfileFormSchema
  >({
    mutationFn: (profile: IProfileFormSchema) =>
      submitUpdateProfile(profile, apiUrl),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: getUserProfileQueryKey(),
      });
    },
  });
};

export default useUpdateProfile;
