import { styled } from '@frontend/theme';
import { Box as MUIBox } from '@mui/material';

export const EmptySearchResultsWrapper = styled(MUIBox)({
  '& first-of-type': {
    marginBottom: '8px',
  },
});

export default EmptySearchResultsWrapper;
