import { useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useContext } from 'react';
import axiosClient from '../axiosClient';
import { ApiContext } from '../ApiContext/ApiContext';
import { getQueryKey } from './getSearchIndex.const';
import {
  TGetSearchIndexResponse,
  TGetSearchIndexResponseFailure,
} from './getSearchIndex.types';
import { IOperationModeType } from '../getCurrentUser/getCurrentUser.types';

const submitGetSearchIndex = (
  apiUrl: string,
  operationMode: IOperationModeType,
) => {
  const queryParams = `?current_operations_mode=${operationMode}`;
  return axiosClient.get(`${apiUrl}/search/index/${queryParams}`);
};

const useGetSearchIndexQuery = (operationMode: IOperationModeType) => {
  const { apiUrl } = useContext(ApiContext);

  return useQuery<
    AxiosResponse<TGetSearchIndexResponse>,
    AxiosError<TGetSearchIndexResponseFailure>,
    AxiosResponse<TGetSearchIndexResponse>
  >({
    queryFn: () => submitGetSearchIndex(apiUrl, operationMode),
    queryKey: getQueryKey(operationMode),
  });
};

export default useGetSearchIndexQuery;
