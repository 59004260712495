export const SEARCHABLE_ITEM_TYPE = {
  REQUEST: 'request',
  LISTING: 'listing',
  ORDER: 'order',
} as const;

export type SearchableItemType =
  (typeof SEARCHABLE_ITEM_TYPE)[keyof typeof SEARCHABLE_ITEM_TYPE];

export interface ISearchableItem {
  itemType: SearchableItemType;
  label: string;
  id: string;
}

export interface SearchBarProps {
  onSelect: (uuid: string | null, searchedTerm: string) => void;
  searchableItems: ISearchableItem[];
  placeholder: string;
}
