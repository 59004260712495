import { styled } from '@frontend/theme';
import { Box as MUIBox } from '@mui/material';
import BaseSideWrapper from '../SideWrapper/SideWrapper.css';

export const PageContainer = styled(BaseSideWrapper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: theme.breakpoints.values.desktopL,
  width: '100%',
  margin: 0,
  paddingTop: '40px',
  paddingBottom: '0px',
}));

export const PageContainerWrapper = styled(MUIBox)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
});
