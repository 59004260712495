import { useEffect, useRef, useState } from 'react';
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { PLATFORM_ROUTES } from '@frontend/routing';
import { useMediaQuery, useTheme } from '@mui/material';
import { RequestListWrapper } from './RequestList.css';
import { IRequestsList } from './RequestList.types';
import { useGetDistanceToTop } from '@customer-platform-shared/utils/useGetDistanceToTop/useGetDistanceToTop';
import RequestCard from './RequestCard/RequestCard';
import { IRequest } from '@frontend/api';

export default function RequestList({ requests }: IRequestsList) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));
  const { uuid } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const refHTMLElement = useRef<HTMLDivElement>(null);
  const distanceToTop = useGetDistanceToTop({ elementRef: refHTMLElement });
  const [searchParams, _] = useSearchParams();
  const [filteredList, setFilteredList] = useState<IRequest[]>([]);

  const handleClick = (clickedRequestUuid: string) => () => {
    const firstRequestDetailsPage = generatePath(
      PLATFORM_ROUTES.requestDetails,
      { uuid: clickedRequestUuid },
    );
    navigate(`${firstRequestDetailsPage}${location.search}`, { replace: true });
  };

  useEffect(() => {
    if (searchParams.has('searchItem')) {
      setFilteredList(
        requests.filter(
          request => request.uuid === searchParams.get('searchItem'),
        ),
      );
    } else {
      setFilteredList(requests);
    }
  }, [uuid, navigate, searchParams, requests]);

  return (
    <RequestListWrapper ref={refHTMLElement} distance={distanceToTop}>
      {filteredList.map(request => (
        <RequestCard
          key={request.uuid}
          request={request}
          expanded={isDesktop ? request.uuid === uuid : true}
          enableScroll={
            isDesktop &&
            request.uuid === uuid &&
            !location.state?.disableScrollToCard
          }
          onClick={handleClick(request.uuid)}
        />
      ))}
    </RequestListWrapper>
  );
}
