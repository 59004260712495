import { Translate } from '@frontend/translation';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { NameRolBox, UserAvatar } from './UserBox.css';
import { IUserBox } from './UserBox.types';
import { RiArrowDownSLine } from '@remixicon/react';
import { RiArrowUpSLine } from '@remixicon/react';
import { HEADER_VARIANTS } from '../PlatformHeader.types';
import { useGetUserProfile } from '@frontend/api';

export default function UserBox({ open = false, variant }: IUserBox) {
  const theme = useTheme();
  const { data: userProfileResponse } = useGetUserProfile();
  const isTabletOrLess = useMediaQuery(theme.breakpoints.down('tabletL'));

  function stringAvatar(name: string) {
    return {
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  }

  return (
    <>
      <UserAvatar
        {...stringAvatar(
          `${userProfileResponse?.data?.user.firstName} ${userProfileResponse?.data?.user.lastName}`,
        )}
      />
      <NameRolBox>
        <Typography variant="p1" color={theme.palette.neutral[500]}>
          {userProfileResponse?.data?.user.firstName}
        </Typography>
        <Typography
          component="p"
          variant="caption1"
          color={theme.palette.neutral[400]}
        >
          {variant === HEADER_VARIANTS.BUYER && (
            <Translate id="customer-platform.header.user.role-area.buyer" />
          )}
          {variant === HEADER_VARIANTS.SELLER && (
            <Translate id="customer-platform.header.user.role-area.seller" />
          )}
        </Typography>
      </NameRolBox>
      {!isTabletOrLess &&
        (open ? (
          <RiArrowUpSLine size={24} color={theme.palette.neutral[500]} />
        ) : (
          <RiArrowDownSLine size={'24px'} color={theme.palette.neutral[500]} />
        ))}
    </>
  );
}
