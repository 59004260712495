import { useEffect, useState } from 'react';
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { IListing, useGetListings } from '@frontend/api';
import { FullPageLoader, PageWrapper } from '@frontend/ui-elements';
import { useMediaQuery, useTheme } from '@mui/material';
import { PageColumns, PageContent } from './ListingDetails.css';
import DashboardHeader from '@customer-platform-shared/components/DashboardHeader/DashboardHeader';
import PlatformHeader from '@customer-platform-shared/components/PlatformHeader/PlatformHeader';
import { PLATFORM_ROUTES } from '@frontend/routing';
import ListingList from './ListingList/ListingList';
import ListingOrderList from './ListingOrderList/ListingOrderList';
import { HEADER_VARIANTS } from '@customer-platform-shared/components/PlatformHeader/PlatformHeader.types';

export default function ListingDetails() {
  const navigate = useNavigate();
  const location = useLocation();
  const { uuid } = useParams();
  const {
    isSuccess: isListingListSuccess,
    isPending: isListingListPending,
    data: listingListResponse,
  } = useGetListings();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));
  const [filteredList, setFilteredList] = useState<IListing[]>([]);
  const [searchParams, _] = useSearchParams();

  useEffect(() => {
    if (isListingListSuccess && listingListResponse) {
      const currentListing =
        listingListResponse.data.find(listing => listing.uuid === uuid) ??
        listingListResponse.data[0];

      if (currentListing) {
        if (currentListing.uuid !== uuid) {
          const urlDestination = generatePath(PLATFORM_ROUTES.listingDetails, {
            uuid: listingListResponse.data[0].uuid,
          });
          navigate(urlDestination, { replace: true });
        }
      } else {
        navigate(PLATFORM_ROUTES.sellerDashboard, { replace: true });
      }
      if (searchParams.has('searchItem')) {
        setFilteredList(
          listingListResponse.data.filter(
            listing => listing.uuid === searchParams.get('searchItem'),
          ),
        );
      } else {
        setFilteredList(listingListResponse.data);
      }
    }
  }, [
    isListingListSuccess,
    listingListResponse,
    uuid,
    navigate,
    searchParams,
    location.search,
  ]);

  if (isListingListSuccess) {
    const currentListing =
      listingListResponse.data.find(listing => listing.uuid === uuid) ??
      listingListResponse.data[0];
    return (
      <>
        <PlatformHeader variant={HEADER_VARIANTS.SELLER} />
        {isDesktop ? (
          <PageWrapper>
            <PageContent>
              <DashboardHeader cardsCount={listingListResponse.data.length} />
              <PageColumns>
                <ListingList listings={filteredList} />
                {currentListing && (
                  <ListingOrderList listing={currentListing} />
                )}
              </PageColumns>
            </PageContent>
          </PageWrapper>
        ) : (
          <PageContent>
            <DashboardHeader />
            {currentListing && <ListingOrderList listing={currentListing} />}
          </PageContent>
        )}
      </>
    );
  }

  if (isListingListPending) {
    return <FullPageLoader />;
  }
}
