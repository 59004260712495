import { useEffect, useRef, useState } from 'react';
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { PLATFORM_ROUTES } from '@frontend/routing';
import { useMediaQuery, useTheme } from '@mui/material';
import { useGetDistanceToTop } from '@customer-platform-shared/utils/useGetDistanceToTop/useGetDistanceToTop';
import { ListingListWrapper } from './ListingList.css';
import ListingCard from './ListingCard/ListingCard';
import { IListingList } from './Listings.types';
import { IListing } from '@frontend/api';

export default function ListingList({ listings }: IListingList) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));
  const { uuid } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const refHTMLElement = useRef<HTMLDivElement>(null);
  const distanceToTop = useGetDistanceToTop({ elementRef: refHTMLElement });
  const [searchParams, _] = useSearchParams();
  const [filteredList, setFilteredList] = useState<IListing[]>([]);

  const handleClick = (clickedListingUuid: string) => () => {
    const firstListingDetailsPage = generatePath(
      PLATFORM_ROUTES.listingDetails,
      { uuid: clickedListingUuid },
    );
    navigate(`${firstListingDetailsPage}${location.search}`, { replace: true });
  };

  useEffect(() => {
    if (searchParams.has('searchItem')) {
      setFilteredList(
        listings.filter(
          request => request.uuid === searchParams.get('searchItem'),
        ),
      );
    } else {
      setFilteredList(listings);
    }
  }, [uuid, navigate, searchParams, listings]);

  return (
    <ListingListWrapper ref={refHTMLElement} distance={distanceToTop}>
      {filteredList.map(listing => (
        <ListingCard
          key={listing.uuid}
          listing={listing}
          expanded={isDesktop ? listing.uuid === uuid : true}
          enableScroll={
            isDesktop &&
            listing.uuid === uuid &&
            !location.state?.disableScrollToCard
          }
          onClick={handleClick(listing.uuid)}
        />
      ))}
    </ListingListWrapper>
  );
}
