import { Typography } from '@mui/material';
import EmptySearchResultsWrapper from './EmptySearchResults.css';
import { EmptySearchResultsProps } from './EmptySearchResults.types';

export default function EmptySearchResults({
  title,
  subtitle,
}: EmptySearchResultsProps) {
  return (
    <EmptySearchResultsWrapper>
      <Typography variant="h5">{title}</Typography>
      <Typography variant="p1">{subtitle}</Typography>
    </EmptySearchResultsWrapper>
  );
}
